define([
  'jquery',
  'modules/common/components/locale',
  'modules/common/components/promisify',

  'upx.modules/ShopModule/models/Repair',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/shop.cash-register-retail/views/popups/confirmPopup',
  'modules/shop.cash-register-retail/models/selectedCustomer',

  'modules/common/components/historyBreadcrumb',
], ($, Locale, Promisify,
  RepairModel,
  CurrentOrderItems, ConfirmPopup, SelectedCustomer,
  HistoryBreadcrumb) => ({

  getStatusTitle(statusName) {
    let translation = '';

    switch (statusName) {
      case 'pre_notified':
        translation = Locale.translate('prenotified');
        break;
      case 'new':
        translation = Locale.translate('new');
        break;
      case 'in_repair':
        translation = Locale.translate('in_repair');
        break;
      case 'on_hold':
        translation = Locale.translate('on_hold');
        break;
      case 'meeting_on_location':
        translation = Locale.translate('meeting_on_location');
        break;
      case 'rma_requested':
        translation = Locale.translate('rma_requested');
        break;
      case 'rma_sent':
        translation = Locale.translate('rma_sent');
        break;
      case 'ready_for_pickup':
        translation = Locale.translate('ready_for_pickup');
        break;
      case 'picked_up':
        translation = Locale.translate('picked_up');
        break;
      case 'finalized':
        translation = Locale.translate('finalized');
        break;
      case 'cancelled':
        translation = Locale.translate('cancelled');
        break;
      default:
        console.warn('No status found ', statusName);
    }

    return translation;
  },

  getStatusClass(statusName) {
    let className = '';

    switch (statusName) {
      case 'pre_notified':
      case 'new':
        className = 'default';
        break;
      case 'in_repair':
        className = 'primary';
        break;
      case 'on_hold':
        className = 'warning';
        break;
      case 'meeting_on_location':
      case 'rma_requested':
      case 'rma_sent':
      case 'ready_for_pickup':
        className = 'info';
        break;
      case 'picked_up':
      case 'finalized':
        className = 'success';
        break;
      case 'cancelled':
        className = 'danger';
        break;
      default:
        console.warn('No class found ', statusName);
    }

    return className;
  },

  pushRepairToOrder(repairId, customer, repairOrderItems) {
    const def = new $.Deferred();
    const confirmDef = new $.Deferred();
    if (CurrentOrderItems.length > 0) {
      (new ConfirmPopup()).open(
        Locale.translate('this_action_will_clear_items_in_order_dot'),
        Locale.translate('there_are_items_in_your_current_order_these_will_be_removed_when_proceeding_dot'),
        confirmDef,
      );
    } else {
      confirmDef.resolve();
    }
    confirmDef.then(() => {
      CurrentOrderItems.clear(); // clear items
      repairOrderItems.forEach((item) => {
        CurrentOrderItems.add(item).save();
      });
      SelectedCustomer.updateFromData(customer.toJSON());
      HistoryBreadcrumb.goto(`payment/repair/${repairId}`);
    });
    return def;
  },

  finaliseRepair(order_id, repair_id) {
    const model = new RepairModel({ id: repair_id, order_id });
    return model.finalize();
  },
}));
