define([
  'modules/common/components/component',
  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
  'modules/shop.cash-register-retail/models/settings/shopPos',
], (
  Component, DefaultShopConfiguration, ShopPosModel,
) => {
  const Comp = Component.extend({

    getStockTextClassFromFlatProduct(shopFlatProductModel) {
      return this.getStockClassFromFlatProduct(shopFlatProductModel, 'text-');
    },

    getClass(stockQuantity, stockUnlimited, stockBackorder, prefix = '') {
      if (stockQuantity <= 0) {
        if (stockUnlimited) return `${prefix}stock-primary`;
        if (stockBackorder) return `${prefix}stock-warning`;
        return `${prefix}stock-danger`;
      }
      return `${prefix}stock-success`;
    },

    getStockClassFromFlatProduct(shopFlatProductModel, prefix = '') {
      const stockQuantity = shopFlatProductModel.get('flat_product.product.product_stock.value');
      const stockUnlimited = shopFlatProductModel.get('flat_product.product.product_stock.unlimited');
      let stockBackorder = false;
      if (shopFlatProductModel.has('backorder_enabled')) {
        stockBackorder = shopFlatProductModel.get('backorder_enabled');
      } else {
        stockBackorder = DefaultShopConfiguration.getFallbackBackorderStatus();
      }
      return this.getClass(stockQuantity, stockUnlimited, stockBackorder, prefix);
    },

    getStockFromFlatProduct(shopFlatProductModel) {
      let stock = shopFlatProductModel.get('flat_product.product.product_stock.value');
      if (shopFlatProductModel.has('orderable_stock_value')) {
        stock = shopFlatProductModel.get('orderable_stock_value');
      }
      return this.getStock(stock, shopFlatProductModel.get('flat_product.product.product_stock.unlimited'));
    },

    getStock(stock, unlimited) {
      if (unlimited) {
        return '∞';
      }
      if (stock > 99) {
        return '99+';
      }
      return stock.toString();
    },

    useStockValues() {
      return ShopPosModel.get('display_stock_values');
    },
  });
  return new Comp();
});
