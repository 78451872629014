define([
  'jquery',
  'modules/common/components/component',
], (
  $, Component,
) => {
  const LOG = '[PRINT QUEUE] ';
  return Component.extend({

    initialize() {
      this.queueKeys = [];
      this.queue = {};
      this.id = 0;
    },

    getQueueForPrinter(printerId) {
      let printerQueue = {};
      if (printerId in this.queue) {
        printerQueue = this.queue[printerId];
      }
      return printerQueue;
    },

    getQueueKeysForPrinter(printerId) {
      let queueKeys = [];
      if (printerId in this.queueKeys) {
        queueKeys = [...this.queueKeys[printerId]]; // get a copy
      }
      return queueKeys;
    },

    addKey(printerId, printId) {
      if (printerId in this.queueKeys) {
        this.queueKeys[printerId].push(printId);
      } else {
        this.queueKeys[printerId] = [printId];
      }
      return this.queueKeys[printerId];
    },

    deleteKey(printerId, printId) {
      let keys = [];
      if (printerId in this.queueKeys) {
        keys = this.queueKeys[printerId];
      }
      const i = keys.indexOf(printId);
      if (i !== -1) {
        keys.splice(i, 1);
      }
      return keys;
    },

    printOnQueue(callback, printerId) {
      const def = new $.Deferred();
      const printId = this.id++;
      const queue = this.getQueueForPrinter(printerId);
      queue[printId] = () => {
        const printerDef = new $.Deferred();
        printerDef.then(def.resolve, def.reject);
        try {
          console.debug(`${LOG}Printing item queue`, {
            printerId,
            printId,
          });
          callback().then(
            printerDef.resolve,
            printerDef.reject,
          );
        } catch (e) {
          printerDef.reject(e);
        }
        return printerDef.promise();
      };
      this.queue[printerId] = queue;
      const keys = this.addKey(printerId, printId);

      console.debug(`${LOG}Added new print to queue`, {
        printerId,
        printId,
        queueLength: keys.length,
      });

      if (keys.length === 1) {
        // first one start printing
        console.debug(`${LOG}Start printing, because first in queue`, {
          printerId,
          printId,
        });
        this.printNextFromQueue(printerId);
      }
      return def.promise();
    },

    printNextFromQueue(printerId) {
      console.debug(`${LOG}Printing next item from queue`, {
        printerId,
      });
      const keys = this.getQueueKeysForPrinter(printerId);
      const key = keys.shift();
      if (key !== undefined) {
        const queue = this.getQueueForPrinter(printerId);
        const printMe = queue[key];
        printMe().always(() => {
          console.debug(`${LOG}Print done`, {
            printerId, key,
          });
          this.deleteKey(printerId, key);
          delete queue[key];
          this.printNextFromQueue(printerId);
        });
      } else {
        console.debug(`${LOG}Queue is empty`, {
          printerId,
        });
      }
    },

  });
});
