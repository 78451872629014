define([
  'underscore',
  'upx.modules/ShopModule/collections/ShopProduct',
  'modules/upx/components/upx',
  'modules/common/components/locale',
], (_, ShopProductCollection, Upx, Locale) => ({

  getCollection() {
    return new ShopProductCollection();
  },

  getStockName(unlimited, backorderEnabled, stockAmount) {
    if (unlimited) {
      return Locale.translate('unlimited');
    } if (backorderEnabled) {
      return Locale.translate("backorder");
    }
    return stockAmount;
  },

  getStockCls(unlimited, backorderEnabled, stockAmount) {
    if (!!unlimited || !!backorderEnabled || stockAmount > 0) {
      return 'label label-success';
    }
    return 'label label-warning';
  },

  getProductTypeName(type, options) {
    options = _.extend({}, {
      fullName: true,
    }, options);
    let name = type;

    switch (type) {
      case 'simple':
        name = Locale.translate("simple");
        break;
      case 'configurable':
        name = Locale.translate("configurable");
        break;
      case 'configurable_assign':
        if (options.fullName) {
          name = Locale.translate("configurable_assigned");
        } else {
          name = Locale.translate("assigned");
        }
        break;
      default:
        name = Locale.translate(type);
        break;
    }

    return name;
  },
}));
