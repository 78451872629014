define([
  'jquery',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/models/settings/scaleType',
], ($, CashRegisterApi, ScaleTypeModel) => ({

  getUrl() {
    switch (ScaleTypeModel.get('id')) {
      case ScaleTypeModel.TYPE_CAS_ER:
        return '/ecr-scale/get';
      case ScaleTypeModel.TYPE_DEV:
        return '/dev-scale/get';
      case ScaleTypeModel.TYPE_ECR_TYPE_6:
        return '/mt-scale/get';
      case ScaleTypeModel.TYPE_SAMSUNG_PORTUGAL:
        return '/samsung-portugal-scale/get';
      default:
        return '/scale/get';
    }
  },

  get() {
    const def = new $.Deferred();

    CashRegisterApi.call(this.getUrl(), 'get')
      .then((data) => {
        def.resolve({
          weight_kg: data.weight_kg.toFixed(2).toString(), // todo for now we only support up to 2 decimals
          is_stable: data.is_stable,
        });
      }, def.reject);

    return def;
  },
}));
