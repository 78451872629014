define([
  'jquery',
  'application',
  'backbone',

  'modules/common/components/component',
  'upx.modules/ShopModule/models/ShopFlatProduct',
  'modules/common/components/locale',
  'modules/common/crontabs/cron',
  'modules/upx/components/upx',
  'modules/shop.cash-register-retail/models/pinKey',
  'modules/common/components/promisify',

  'modules/shop.cash-register-retail/collections/upx/FeaturedAttribute',
  'modules/shop.cash-register-retail/models/orderItem',
  'modules/shop.cash-register-retail/models/productCacheConfig',
  'modules/common/components/idle',
  'dexie',

  'modules/common/collections/DeepModelCollection',
], (
  $, App, Backbone,
  Component, ShopFlatProductModel, Locale, Cron, Upx, PinKey, Promisify,
  FeaturedAttributeCollection, OrderItemModel, ProductCacheConfigModel, IdleComponent, Dexie,
) => {
  const ProductCache = Component.extend({

    NEW_PRODUCT_SYNC_DELAY: 15 * 60 * 1000, // time between new product sync
    UPDATE_PRODUCT_SYNC_DELAY: 15 * 60 * 1000, // time between update
    INACTIVE_TIME: 5 * 60 * 1000, // how long user needs to be inactive before it starts synching
    STALE_TIME: 30 * 60 * 1000, // after what time the product is always refetched, even if it hits cache

    PRODUCT_LIMIT: 250, // amount of products to fetch at once on synch

    initialize() {
      this.db = new Dexie('productCache');
      this.db.version(1)
        .stores({
          products: 'shop_product_id, time, &barcode, product_id',
        });
      this.db.on('populate', () => {
        // reset to default on db creation -> no cache present
        ProductCacheConfigModel.resetToDefaults();
      });
      this.isSynching = false;
      this.lastNewProductSync = null;
      this.lastUpdateProductSync = null;
      this.cleanUpdate = true;

      const self = this;
      const CronClass = Cron.extend({
        cron: '* * * * *',
        run() {
          self.runCron();
        },
      });
      this.cron = new CronClass();
      if (window.location.hash !== '#customer-screen') {
        this.cron.start();
      }
    },

    getBarcodeId() {
      const barcodeAttr = FeaturedAttributeCollection.getAttributeByAlias('barcode');
      if (barcodeAttr) {
        return barcodeAttr.get('attribute_id');
      }
      return false;
    },

    async syncFeaturedAttributes() {
      if (FeaturedAttributeCollection.length === 0) {
        // no attributes are loaded try to load them
        await Promisify.deferredToPromise(FeaturedAttributeCollection.load(true));
      }
    },

    canLoadNewProducts() {
      return !this.lastNewProductSync
        || this.lastNewProductSync.getTime() + this.NEW_PRODUCT_SYNC_DELAY < new Date().getTime();
    },

    canUpdateOldProducts() {
      return !this.lastUpdateProductSync
        || this.lastUpdateProductSync.getTime() + this.UPDATE_PRODUCT_SYNC_DELAY < new Date().getTime();
    },

    isUserInactive() {
      return IdleComponent.getLastActive().getTime() + this.INACTIVE_TIME < new Date().getTime();
    },

    isLocked() {
      return window.location.hash === '#auth';
    },

    canSyncProducts() {
      if (this.isLocked() || this.isUserInactive()) {
        return true;
      }
      return false;
    },
    isStaleProduct(model) {
      return model.get('time') + this.STALE_TIME < new Date().getTime();
    },
    async runCron(force = false) {
      if (!this.isSynching) {
        try {
          this.isSynching = true;
          if (!PinKey.isEnabled()) {
            return; // no auth to perform the sync
          }

          const barcodeId = this.getBarcodeId();
          if (!barcodeId) {
            await this.syncFeaturedAttributes(); // no barcode try to sync
          }

          // open the db to make sure all upgrades are run before processing
          await this.db.open();

          // new products have more priority that`s why run first
          if (force || this.canLoadNewProducts()) {
            await this.syncNewProducts();
          }

          if (force || this.canUpdateOldProducts()) {
            await this.syncUpdateProducts();
          }
        } catch (e) {
          console.warn('Failed to synchronize product cache', e);
        } finally {
          this.isSynching = false;
        }
      }
    },

    getBarcodeFromShopFlatProduct(shopProduct) {
      let barcode = null;
      const barcodeId = this.getBarcodeId();
      if (barcodeId) {
        const content_vars = shopProduct.get('flat_product.content_vars') || [];
        content_vars.forEach((content_var) => {
          if (content_var.attribute_id === barcodeId) {
            barcode = content_var.value;
          }
        });
      }
      return barcode;
    },

    getCacheRowFromShopProduct(shopProduct) {
      return {
        barcode: this.getBarcodeFromShopFlatProduct(shopProduct),
        shop_product_id: shopProduct.get('id'),
        product_id: shopProduct.get('product_id'),
        shop_flat_product: shopProduct.toJSON(),
        time: new Date().getTime(),
      };
    },

    async storeShopProduct(shopProduct) {
      const cacheRow = this.getCacheRowFromShopProduct(shopProduct);
      const { barcode } = cacheRow;
      if (barcode) {
        const oldProduct = await this.db.products.get({ barcode });
        if (oldProduct && oldProduct.shop_product_id !== cacheRow.shop_product_id) {
          // found some other product with same barcode -> remove it
          await this.db.products.delete(oldProduct.shop_product_id);
        }
      }
      await this.db.products.put(cacheRow);
      console.debug(`[ProductCache] Updated product (${shopProduct.get('id')}) successfully`);
      return new OrderItemModel(cacheRow);
    },

    async syncWithCallback(callback) {
      if (this.canSyncProducts()) {
        await callback();
        return true;
      }
      return false;
    },

    async syncNewProducts() {
      return this.syncWithCallback(async () => this.loadNewProducts());
    },

    callProductSearch(params, fnName = 'naturalSearchShopFlatProducts') {
      return Promisify.deferredToPromise(
        PinKey.callWithAuthIfEnabled('ShopModule', fnName, params),
      );
    },

    async fetchShopProductByProductId(productId) {
      const model = await this.readByProductIdFromStore(productId);
      let shopProduct = null;
      if (model) {
        shopProduct = new ShopFlatProductModel(model.get('shop_flat_product'));
      } else {
        const params = {
          query: 0,
          lang: 0,
          start: 0,
          limit: 1,
          filters: [{
            name: 'product_id',
            val: productId,
          }],
        };
        const searchResponse = await this.callProductSearch(params);
        if (searchResponse.count === 1) {
          shopProduct = new ShopFlatProductModel(searchResponse.data[0]);
          await this.storeShopProduct(shopProduct);
        }
      }
      if (!shopProduct) {
        throw new Error(`Product with id=${productId} not found`);
      }
      return shopProduct;
    },

    async cacheShopProductFromBackendForNew(params) {
      const searchResponse = await this.callProductSearch(params);
      await Promise.all(searchResponse.data.map(async (shopProductData) => {
        const shopProduct = new ShopFlatProductModel(shopProductData);
        const triedToSave = await this.storeShopProduct(shopProduct);

        if (triedToSave) {
          ProductCacheConfigModel.setLastId(shopProduct.get('id'));
        }
      }));
      return searchResponse;
    },

    async cacheShopProductFromBackendForUpdate(params) {
      const searchResponse = await this.callProductSearch(params, 'naturalSearchShopFlatProductsForCacheUpdate');
      await Promise.all(searchResponse.data.map(async (shopProductData) => {
        const shopProduct = new ShopFlatProductModel(shopProductData);
        await this.storeShopProduct(shopProduct);
      }));
      return searchResponse;
    },

    async loadNewProducts() {
      const barcodeId = this.getBarcodeId();
      if (!barcodeId) {
        throw new Error('No barcode featured attribute found');
      }

      const lastId = ProductCacheConfigModel.getLastId();
      const limit = this.PRODUCT_LIMIT;
      console.log(`[ProductCache] NewProducts, loading more products id > ${lastId}`);

      const params = {
        query: 0,
        lang: 0,
        start: 0,
        limit,
        sort: [{
          name: 'id',
          dir: 'asc',
        }],
        filters: [{
          name: 'id__>',
          val: lastId,
        }],
      };
      const searchResponse = await this.cacheShopProductFromBackendForNew(params);
      if (searchResponse.count < limit) {
        this.lastNewProductSync = new Date(); // full sync finished
        console.log('[ProductCache] All products are synchronized');
        return; // nothing more to sync
      }

      // load more
      const count = await this.db.products.count();
      const toGo = searchResponse.total - searchResponse.count;
      const total = toGo + count;
      const progress = 100 - (toGo * 100 / total);

      console.log(`[ProductCache] NewProducts left to sync ${toGo} [progress: ${progress.toFixed(2)} %]`);
      await this.syncNewProducts();
    },

    syncUpdateProducts() {
      return this.syncWithCallback(async () => this.loadUpdateProducts());
    },

    async loadUpdateProducts() {
      const barcodeId = this.getBarcodeId();
      if (!barcodeId) {
        throw new Error('No barcode featured attribute found');
      }

      const lastTime = ProductCacheConfigModel.getLastUpdateDate();
      const lastIds = ProductCacheConfigModel.getUpdateDateIds();
      if (this.cleanUpdate) {
        this.lastUpdateProductSync = new Date();
        this.cleanUpdate = false;
      }

      const limit = this.PRODUCT_LIMIT;
      console.log(`[ProductCache] UpdateProducts, loading more products updated after ${lastTime}, already cached ids: `, lastIds);

      const params = {
        query: 0,
        lang: 0,
        start: 0,
        limit,
        sort: [
          {
            name: 'flat_product/product/date_updated',
            dir: 'asc',
          },
          {
            name: 'id',
            dir: 'asc',
          },
        ],
        update_after_incl: lastTime.toISOString(),
        exclude_ids: lastIds,
        filters: [
          {
            // only the ones already loaded
            name: 'id__<=',
            val: ProductCacheConfigModel.getLastId(),
          },
        ],
      };
      const searchResponse = await this.cacheShopProductFromBackendForUpdate(params);
      const { data } = searchResponse;
      // set to current date, in case the list is empty and nothing to update
      if (data.length > 0) {
        const lastProduct = new Backbone.DeepModel(data[data.length - 1]);
        const lastDateString = lastProduct.get('flat_product.product.date_updated');
        const lastDateTime = new Date(lastDateString);
        const ids = data
          .filter((row) => row.flat_product.product.date_updated === lastDateString)
          .map(({ id }) => id);
        ProductCacheConfigModel.setUpdateDateIds(lastDateTime, ids);
      }

      if (searchResponse.count < limit) {
        this.cleanUpdate = true;
        console.log('[ProductCache] UpdateProducts synchronized');
        return; // nothing more to sync
      }

      // load more
      const toGo = searchResponse.total - searchResponse.count;
      console.log(`[ProductCache] UpdateProducts left to sync=${toGo} (synchronized ${searchResponse.count})`);
      await this.syncUpdateProducts();
    },

    async refreshProduct(shop_product_id) {
      const shopFlatProductModel = new ShopFlatProductModel();
      const params = {
        query: 0,
        lang: 0,
        start: 0,
        limit: 1,
        filters: [{
          name: 'id',
          val: shop_product_id,
        }],
      };
      const searchResponse = await Promisify.deferredToPromise(
        shopFlatProductModel.naturalSearch(params),
      );

      if (searchResponse.total === 1) {
        // only one result found, so that`s it
        const first = searchResponse.data[0];
        const model = new ShopFlatProductModel(first);
        await this.storeShopProduct(model);
        return true;
      }
      await this.db.products.delete({
        shop_product_id,
      });
      return false;
    },

    async refreshStaleModel(model) {
      if (this.isStaleProduct(model)) {
        await this.refreshProduct(model.get('shop_product_id'));
        return true;
      }
      return false;
    },

    async refreshProductStockAndPrice(shop_product_id) {
      let cachedVersion = await this.db.products.get({
        shop_product_id,
      });

      if (!cachedVersion || !cachedVersion.shop_flat_product) {
        console.warn('refreshProductStockAndPrice requested but product is not cached', shop_product_id);
        if (!await this.refreshProduct(shop_product_id)) {
          console.warn('failed to cache product', shop_product_id);
          return false;
        }
        cachedVersion = await this.db.products.get({
          shop_product_id,
        });
      }

      const searchResponse = await Promisify.deferredToPromise(
        Upx.call('ShopModule', 'listShopProductStocks',
          {
            start: 0,
            limit: 1,
            filters: [{
              name: 'id',
              val: shop_product_id,
            }],
          }),
      );

      const replaceFields = [
        'product_price',
        'product_price_id',
        'product_default_price',
        'product_default_price_id',
        'unfulfilled_stock_value',
        'unfulfilled_order_ids',
        'orderable_stock_value',
        'backorder_enabled',
      ];
      if (searchResponse.total === 1) {
        const newData = searchResponse.data[0];
        // only one result found, so that`s it
        const model = new ShopFlatProductModel(cachedVersion.shop_flat_product);
        model.unset('flat_product.product.product_stock');
        model.set('flat_product.product.product_stock', newData.product.product_stock);

        replaceFields.forEach((name) => {
          model.unset(name);
          if (name in newData) {
            model.set(name, newData[name]);
          }
        });
        return await this.storeShopProduct(model);
      }
      await this.db.products.delete({
        shop_product_id,
      });
      return false;
    },

    async readByBarcodeFromStore(barcode) {
      console.log(`Searching for barcode: ${barcode}`);

      const data = await this.db.products.get({
        barcode,
      });
      if (data) {
        const model = new OrderItemModel(data);
        console.log(`Barcode found: ${barcode}`);

        this.refreshStaleModel(model).then(
          () => console.debug(`[ProductCache] Refreshed product: ${model.get('barcode')}`),
        );
        return model;
      }
      return false;
    },

    async readByProductIdFromStore(productId) {
      const data = await this.db.products.get({
        product_id: productId,
      });
      if (data) {
        const model = new OrderItemModel(data);
        this.refreshStaleModel(model);
        return model;
      }
      return false;
    },

    getEanUpcOpposite(barcode) {
      let opposite = false;
      if (barcode.length === 12 || barcode.length === 7) {
        // Us barcode -> search again with 0 at the beginning
        opposite = `0${barcode}`;
      } else if (barcode.length === 13 || barcode.length === 8) {
        // check if starts with 0
        if (barcode[0] === '0') {
          // US -> let`s search for one without 0
          opposite = barcode.substr(1);
        }
      }
      return opposite;
    },
    getPossibleBarcodes(barcode) {
      const barcodes = [barcode];
      if (!!barcode && barcode.length > 2) {
        const opposite = this.getEanUpcOpposite(barcode);
        if (opposite) {
          // proper UPC -> EAN conversion (or other direction)
          barcodes.push(opposite);
        }
        // generate all zero possibilities, min code size 1
        while (barcode.length > 1 && barcode[0] === '0') {
          barcode = barcode.substr(1);
          barcodes.push(barcode);
        }
      }
      return barcodes;
    },

    async findByBarcodes(barcodes, processBarcodes) {
      processBarcodes = processBarcodes || barcodes.slice(0);

      if (processBarcodes.length === 0) {
        throw new Error(`No cached barcode found. Searched for: ${barcodes.join(',')}`);
      }
      const barcode = processBarcodes.shift();
      const product = await this.readByBarcodeFromStore(barcode);
      if (product) {
        return product; // found
      }
      return this.findByBarcodes(barcodes, processBarcodes);
    },

    findByBarcode(query) {
      query = query.trim();
      const barcodes = this.getPossibleBarcodes(query);
      return Promisify.promiseToDeferred(this.findByBarcodes(barcodes));
    },

    async clearProductCache() {
      const { localStorage, indexedDB } = window;

      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.indexOf('productCacheConfig') > 0) {
          localStorage.removeItem(key);
          console.log('Removed localStorage key', key);
        }
      }
      const dbs = await indexedDB.databases();
      for (let i = 0; i < dbs.length; i++) {
        const { name } = dbs[i];
        if (name === 'productCache') {
          // eslint-disable-next-line no-await-in-loop
          const reqB = indexedDB.deleteDatabase(name);
          reqB.onsuccess = function () {
            console.log('Removed indexedDB database', name);
          };
          reqB.onerror = function (event) {
            console.error('Failed to remove ', name, event);
          };
        }
      }
    },
  });

  const UpxProductCache = new ProductCache();
  window.UpxProductCache = UpxProductCache;
  return UpxProductCache;
});
