define([
  'jquery',
  'underscore',
  'backbone',

  'modules/common/components/locale',
  'modules/common/components/component',
  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',

  'modules/shop.cash-register-retail/collections/upx/ShippingType.js',
  'modules/shop.cash-register-retail/collections/upx/ShipmentStatusType.js',
], (
  $, _, Backbone,
  Locale, Component, Configuration,
  ShippingTypeCollection, ShipmentStatusTypeCollection,

) => {
  const Comp = Component.extend({
    TYPE_PICK_AT_STORE: 'PickupAtStore',
    TYPE_PARCEL: 'Parcel',
    TYPE_TRUCK_DELIVERY: 'TruckDelivery',
    TYPE_OTHER: 'Other',
    TYPE_SAME_DAY: 'SameDay',

    isFallback(model) {
      const fallbackMethodId = Configuration.getFallbackShippingMethodId();
      return `${model.get('shipping_method_id')}` === `${fallbackMethodId}`;
    },
    getNameForOrder(model) {
      const isFallbackMethod = this.isFallback(model);
      if (!isFallbackMethod
        && model.get('shipping_method_id')
        && model.get('shipping_name')
        && model.get('shipping_name') !== model.get('shipping_method.name')
      ) {
        return `${model.get('shipping_name')} (${model.get('shipping_method.name')})`;
      }
      return model.get('shipping_name') || '';
    },
    getIconForOrder(model) {
      const isFallbackMethod = this.isFallback(model);
      if (!isFallbackMethod && model.get('shipping_method_id')) {
        const type = ShippingTypeCollection.get(model.get('shipping_method.shipping_type_id'));
        if (type) {
          return this.getIconForType(type.get('alias'));
        }
      }
      return '';
    },

    getIconForShipment(model) {
      const typeId = model.get('shipping_method.shipping_type_id');
      if (typeId) {
        const type = ShippingTypeCollection.get(typeId);
        if (type) {
          return this.getIconForType(type.get('alias'));
        }
      }
      return '';
    },

    getIconForType(alias) {
      switch (alias) {
        case this.TYPE_PICK_AT_STORE:
          return 'fas fa-store-alt';
        case this.TYPE_PARCEL:
          return 'fas fa-box';
        case this.TYPE_TRUCK_DELIVERY:
          return 'fas fa-truck';
        case this.TYPE_SAME_DAY:
          return 'fas fa-shipping-fast';
        case this.TYPE_OTHER:
          return 'fas fa-question';
      }
      console.error('No icon shipment type alias defined', alias);
      return '';
    },

    isShipmentDelivered(model) {
      const status = model.get('shipment_status_type.alias');
      return ShipmentStatusTypeCollection.STATUS_DELIVERED === status;
    },
    getStatusName(status) {
      return ShipmentStatusTypeCollection.getStatusName(status);
    },
    getStatusLabelClass(status) {
      return ShipmentStatusTypeCollection.getStatusLabelClass(status);
    },
  });
  return new Comp();
});
